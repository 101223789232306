import React, { useCallback, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SwitchBtn } from "../../hooks/translate/switchBtn";
import { useScrollPosition } from "../../hooks/scroll";
import { ParticleCanvas } from "./animate/ParticlesCanvas";
import { Radio } from "../radio/radio";
import avaImg from "../../assets/logo/kovadlo1.png";
import "./headerStyle.scss";

export const Header = ({ mod }) => {
  const navigate = useNavigate();
  const headerResize = useScrollPosition();
  const { t } = useTranslation();
  const [isAnimate, setIsAnimate] = useState(false);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleLogoClick = useCallback(() => {
    navigate("/");
    scrollToTop();
  }, [navigate, scrollToTop]);

  return (
    <main className="headers">
      <div
        className={`header ${
          headerResize ? "header--short" : "header--normal"
        }`}
      >
        <img
          className="header__logo"
          onClick={handleLogoClick}
          src={avaImg}
          alt="MBO-logo"
        />

        {/* {!isAnimate && <ParticleCanvas headerResize={headerResize} />} */}
        <ParticleCanvas headerResize={headerResize} />
        <SwitchBtn headerResize={headerResize} />

        <button
          className={`scrollBtn ${headerResize ? "visible" : "hidden"}`}
          onClick={scrollToTop}
        >
          <span className={`scrollBtn__arrow`}></span>
        </button>
      </div>

      <Radio isAnimate={isAnimate} setIsAnimate={setIsAnimate} />
    </main>
  );
};
