import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./i18n";

// Імпортування зображень прапорців
import enFlag from "../../assets/img/icon-en.png";
import uaFlag from "../../assets/img/icon-ua.png";

import uaFlag1 from "../../assets/img/flagIcon-ua.png";
import enFlag1 from "../../assets/img/flagIcon-uk.png";

const flags = [
  { lang: "en", img: enFlag, img1: enFlag1, label: "English" },
  { lang: "ua", img: uaFlag, img1: uaFlag1, label: "Українська" },
];

export function SwitchBtn() {
  const { i18n } = useTranslation();
  const [currentFlag, setCurrentFlag] = useState(0);
  const [showLangMenu, setShowLangMenu] = useState(false);

  useEffect(() => {
    // Отримання збереженої мови з localStorage
    const savedLang = localStorage.getItem("lang") || "en";
    const foundIndex = flags.findIndex((flag) => flag.lang === savedLang);
    if (foundIndex !== -1) {
      setCurrentFlag(foundIndex);
      i18n.changeLanguage(savedLang);
    }
  }, [i18n]);

  const handleMouseEnter = () => {
    setShowLangMenu(true);
  };

  const handleMouseLeave = () => {
    setShowLangMenu(false);
  };

  const changeLanguage = (index) => {
    setCurrentFlag(index);
    const nextLang = flags[index].lang;
    i18n.changeLanguage(nextLang);
    localStorage.setItem("lang", nextLang); // Збереження обраної мови
    setShowLangMenu(false); // Закрити меню після вибору мови
  };

  return (
    <main
      className="header__wrap"
      onClick={() => setShowLangMenu(!showLangMenu)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="header__flag">
        <img
          className="header__flag-img"
          src={flags[currentFlag].img}
          alt="Current flag"
          style={{ transform: `translateX(0%)` }}
        />
      </div>
      {showLangMenu && (
        <div
          className={`header__menu ${
            showLangMenu ? "header__menu--large" : ""
          }`}
        >
          {flags.map((flag, index) => (
            <div
              key={index}
              className={`header__menu-flag ${
                index === currentFlag ? "header__btn2" : ""
              }`}
              onClick={() => changeLanguage(index)}
            >
              <div className="header__row">
                <img
                  className="header__flag-img1"
                  src={flag.img1}
                  alt="flagImg"
                />
                <p>{flag.label}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </main>
  );
}
