import { useState, useEffect } from "react";

export const useScrollPosition = () => {
  const [isWidthLessThan600, setIsWidthLessThan600] = useState(
    window.innerWidth <= 600
  );
  const [isScrollBelow500, setIsScrollBelow500] = useState(
    window.scrollY <= 150
  );

  useEffect(() => {
    const checkWindowWidth = () => {
      setIsWidthLessThan600(window.innerWidth <= 600);
    };
    const checkScrollPosition = () => {
      setIsScrollBelow500(
        window.innerWidth > 600 ? window.scrollY > 150 : false
      );
    };
    window.addEventListener("resize", checkWindowWidth);
    window.addEventListener("scroll", checkScrollPosition);

    // Перевірка при монтажі, щоб визначити початковий стан
    checkWindowWidth();
    checkScrollPosition();
    return () => {
      window.removeEventListener("resize", checkWindowWidth);
      window.removeEventListener("scroll", checkScrollPosition);
    };
  }, []);
  return isWidthLessThan600 || isScrollBelow500;
};

export const ScrollToElement = (elem) => {
  const scrollPoint = document.getElementById(elem);
  const headerHeight = 60;

  if (scrollPoint) {
    const scrollPosition =
      scrollPoint.getBoundingClientRect().top + window.scrollY - headerHeight;

    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    });
    scrollPoint.classList.add("open");
  } else {
    console.error(`Element with ID ${elem} not found`);
  }
};
