import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../../../components/spinner";
import "./cardStyle.scss";

// Імпортуємо всі зображення з папки assets/logo-tech/
const images = require.context(
  "../../../assets/logo-tech/",
  false,
  /\.(png|jpe?g|svg)$/
);

export const ProjectCard = ({
  title,
  gifSrc,
  url,
  url2,
  tech = [],
  description,
}) => {
  const { t } = useTranslation();
  const [isFlipped, setIsFlipped] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <main
      className={`projectCard ${isFlipped ? "projectCard--flipped" : ""}`}
      onMouseLeave={() => setIsFlipped(false)}
    >
      <div className="projectCard__inner">
        <section
          className="projectCard__frontSide"
          onClick={() => setIsFlipped(true)}
        >
          <h3 className="projectCard__title">{title}</h3>
          <div className="projectCard__image">
            {!isLoaded && <Spinner />}
            <img
              src={gifSrc}
              alt={title}
              style={{ display: isLoaded ? "block" : "none" }}
              onLoad={() => setIsLoaded(true)}
            />
          </div>
          <p className="projectCard__description">{description}</p>
          <BottomLinks url={url} url2={url2} side={t("more_info")} />
        </section>

        <section
          className="projectCard__backSide"
          onClick={() => setIsFlipped(false)}
        >
          <div className="projectCard__tech">
            <h3 className="projectCard__title">{t("used_technologies")}</h3>
            {tech.length > 0 ? (
              tech.map((techName, index) => {
                const techImg = images(`./${techName}.png`);
                return (
                  <img
                    key={index}
                    src={techImg}
                    alt={techName}
                    className="projectCard__techImg"
                  />
                );
              })
            ) : (
              <p>{t("no_tech_available")}</p>
            )}
          </div>
          <BottomLinks url={url} url2={url2} side={t("back")} />
        </section>
      </div>
    </main>
  );
};

const BottomLinks = ({ url, url2, side }) => {
  const { t } = useTranslation();
  const [activeElement, setActiveElement] = useState("button");

  return (
    <div
      className="projectCard__wrap"
      onMouseLeave={() => setActiveElement("button")}
    >
      <button
        className={`projectCard__btn ${
          activeElement === "button" ? "projectCard__btn--active" : ""
        }`}
        onMouseEnter={() => setActiveElement("button")}
      >
        {side}
      </button>

      <a
        className={`projectCard__url ${
          activeElement === "url" ? "projectCard__btn--active" : ""
        }`}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onMouseEnter={() => setActiveElement("url")}
      >
        {t("visit_website")}
      </a>

      {url2 && (
        <a
          className={`projectCard__url2 ${
            activeElement === "url2" ? "projectCard__btn--active" : ""
          }`}
          href={url2}
          target="_blank"
          rel="noopener noreferrer"
          onMouseEnter={() => setActiveElement("url2")}
        >
          Github
        </a>
      )}
    </div>
  );
};
