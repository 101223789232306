import React, { useState, useRef, useEffect } from "react";
import { Player } from "./player";
import { Playlist } from "./playlist";
import { useTranslation } from "react-i18next";
import "./radioStyle.scss";

export const Radio = ({ isAnimate, setIsAnimate }) => {
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [videos, setVideos] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const { t } = useTranslation();
  const playerRef = useRef(null);

  const handleVideoSelect = (videoId, index) => {
    setCurrentVideoId(videoId);
    setCurrentIndex(index);
  };

  const handleNextTrack = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setCurrentIndex((prevIndex) => {
      const nextIndex = (prevIndex + 1) % videos.length;
      return nextIndex;
    });
  };

  const handlePrevTrack = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + videos.length) % videos.length;
      return newIndex;
    });
  };

  const handlePlay = (event) => {
    event.stopPropagation();
    if (
      playerRef.current &&
      typeof playerRef.current.playVideo === "function"
    ) {
      playerRef.current.playVideo();
    }
    setIsPlaying(true);
    setIsAnimate(true);
  };

  const handleStop = (event) => {
    event.stopPropagation();
    if (
      playerRef.current &&
      typeof playerRef.current.pauseVideo === "function"
    ) {
      playerRef.current.pauseVideo();
    }
    setIsPlaying(false);
    setIsAnimate(false);
  };

  const handlePause = () => {
    if (playerRef.current) {
      playerRef.current.pauseVideo();
      setIsPlaying(false);
      setIsAnimate(false);
    }
  };

  useEffect(() => {
    if (videos.length > 0) {
      const videoId = videos[currentIndex]?.snippet?.resourceId?.videoId;
      setCurrentVideoId(videoId || currentVideoId);
      setIsPlaying(true);
      setIsAnimate(true);
    }
  }, [currentIndex, videos, currentVideoId, setIsAnimate]);

  const handleExpandClose = (e) => {
    e.stopPropagation();
    setIsExpanded(false);
  };

  return (
    <div
      className="radio"
      style={{
        height: isExpanded ? "550px" : "35px",
        width: isExpanded ? "100%" : "190px",
      }}
      onMouseLeave={(e) => {
        handleExpandClose(e);
      }}
      onClick={() => {
        setIsExpanded(true);
      }}
    >
      {isExpanded && (
        <span className="radio__cross" onClick={(e) => handleExpandClose(e)}>
          &times;
        </span>
      )}
      <div className={`radio__compact ${isExpanded && "hidden"}`}>
        <h1 className="radio__title">{t("radio")}</h1>
        <img
          className="player__img"
          src="https://cdn-icons-png.flaticon.com/128/5577/5577228.png"
          onClick={handlePlay}
          alt="Play"
        />
        <img
          className="player__img"
          src="https://cdn-icons-png.flaticon.com/128/3669/3669483.png"
          onClick={handleStop}
          alt="Pause"
        />
        <img
          className="player__img"
          src="https://cdn-icons-png.flaticon.com/128/11908/11908101.png"
          onClick={handleNextTrack}
          alt="Next"
        />
      </div>

      <div className={`radio__expanded ${isExpanded ? "" : "hidden"}`}>
        <Playlist
          selectVideo={handleVideoSelect}
          setVideos={setVideos}
          currentVideoId={currentVideoId}
          setIsExpanded={setIsExpanded}
          t={t}
        />
        {videos.length > 0 && (
          <Player
            ref={playerRef}
            videoId={currentVideoId}
            onNextTrack={handleNextTrack}
            onPrevTrack={handlePrevTrack}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            setIsAnimate={setIsAnimate}
          />
        )}
      </div>
    </div>
  );
};
