import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";

export const Player = forwardRef(
  (
    {
      videoId,
      onNextTrack,
      onPrevTrack,
      isPlaying,
      setIsPlaying,
      setIsAnimate,
    },
    ref
  ) => {
    const playerRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isPlayerReady, setIsPlayerReady] = useState(false);

    useImperativeHandle(ref, () => ({
      playVideo: () => {
        if (
          playerRef.current &&
          typeof playerRef.current.playVideo === "function"
        ) {
          playerRef.current.playVideo();
        }
      },
      pauseVideo: () => {
        if (
          playerRef.current &&
          typeof playerRef.current.pauseVideo === "function"
        ) {
          playerRef.current.pauseVideo();
        }
      },
      getMediaElement: () => {
        return playerRef.current
          .getIframe()
          .contentWindow.document.querySelector("video");
      },
    }));

    const createOrUpdatePlayer = () => {
      if (
        playerRef.current &&
        typeof playerRef.current.loadVideoById === "function"
      ) {
        playerRef.current.loadVideoById(videoId);
        setTimeout(() => {
          setDuration(playerRef.current.getDuration());
        }, 1000);
      } else {
        playerRef.current = new window.YT.Player("player", {
          videoId,
          playerVars: {
            controls: 1,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
          },
          events: {
            onReady: (event) => {
              setIsPlayerReady(true);
              setTimeout(() => {
                setDuration(event.target.getDuration());
              }, 1000);
              if (isPlaying) {
                event.target.playVideo();
              }
            },
            onStateChange: (event) => {
              if (event.data === window.YT.PlayerState.ENDED) {
                onNextTrack(); // Автоматичне перемикання на наступний трек
              } else if (event.data === window.YT.PlayerState.PLAYING) {
                setIsPlaying(true);
                setIsAnimate(true);
              } else if (event.data === window.YT.PlayerState.PAUSED) {
                setIsPlaying(false);
                setIsAnimate(false);
              }
            },
          },
        });
      }
    };

    useEffect(() => {
      if (window.YT) {
        createOrUpdatePlayer(); // Викликаємо функцію створення або оновлення плеєра, якщо API вже доступний
      } else {
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        document.body.appendChild(tag);

        window.onYouTubeIframeAPIReady = () => {
          createOrUpdatePlayer(); // Створюємо плеєр після завантаження API
        };
      }
    }, [videoId]); // Цей хук викликається при зміні videoId

    // Оновлення часу треку кожну секунду
    useEffect(() => {
      const interval = setInterval(() => {
        if (
          playerRef.current &&
          isPlaying &&
          isPlayerReady &&
          typeof playerRef.current.getCurrentTime === "function"
        ) {
          setCurrentTime(playerRef.current.getCurrentTime());
        }
      }, 1000);
      return () => clearInterval(interval);
    }, [isPlaying, isPlayerReady]);

    const handlePlayInternal = () => {
      if (playerRef.current && isPlayerReady) {
        playerRef.current.playVideo();
      }
    };

    const handleStopInternal = () => {
      if (playerRef.current && isPlayerReady) {
        playerRef.current.stopVideo();
        setIsPlaying(false);
        setCurrentTime(0); // Обнуляємо час при зупинці
      }
    };

    const handlePause = () => {
      if (playerRef.current && isPlayerReady) {
        playerRef.current.pauseVideo();
        setIsPlaying(false);
        setIsAnimate(false);
      }
    };

    const handleSeek = (e) => {
      const newTime = e.target.value;
      setCurrentTime(newTime);
      if (playerRef.current && isPlayerReady) {
        playerRef.current.seekTo(newTime); // Перемотка треку
      }
    };

    const formatTime = (timeInSeconds) => {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = Math.floor(timeInSeconds % 60);
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    return (
      <main className="player">
        <div
          id="player"
          style={{ width: "0", height: "0", visibility: "hidden" }}
        ></div>

        <div className="player__trackBar">
          <input
            className="player__input"
            type="range"
            min="0"
            max={duration}
            value={currentTime}
            onChange={handleSeek}
            style={{
              backgroundImage: `linear-gradient(to right, #009fe3 0%, #009fe3 ${
                (currentTime / duration) * 100
              }%, transparent ${
                (currentTime / duration) * 100
              }%, transparent 100%)`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%", // Задаємо розмір фону
            }}
          />
          <p className="player__time">
            {formatTime(currentTime)} / {formatTime(duration)}
          </p>
        </div>

        <div className="player__navAudio">
          <img
            className="player__img player__img--large"
            src="https://cdn-icons-png.flaticon.com/128/254/254437.png"
            onClick={onPrevTrack}
            alt="Prev"
          />
          <img
            className="player__img player__img--large"
            src="https://cdn-icons-png.flaticon.com/128/4029/4029077.png"
            onClick={handleStopInternal}
            alt="Stop"
          />
          <img
            className="player__img player__img--large"
            src="https://cdn-icons-png.flaticon.com/128/5577/5577228.png"
            onClick={handlePlayInternal}
            alt="Play"
          />
          <img
            className="player__img player__img--large"
            src="https://cdn-icons-png.flaticon.com/128/3669/3669483.png"
            onClick={handlePause}
            alt="Pause"
          />
          <img
            className="player__img player__img--large"
            src="https://cdn-icons-png.flaticon.com/128/11908/11908101.png"
            onClick={onNextTrack}
            alt="Next"
          />
        </div>
      </main>
    );
  }
);
