import React, { useEffect, useState } from "react";
import axios from "axios";

export const Playlist = ({ selectVideo, setVideos, currentVideoId, t }) => {
  // Додаємо currentVideoId
  const [videosList, setVideosList] = useState([]);

  useEffect(() => {
    const fetchPlaylist = async () => {
      const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;
      const PLAYLIST_ID = process.env.REACT_APP_YOUTUBE_PLAYLIST_1;
      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/playlistItems`,
          {
            params: {
              part: "snippet",
              maxResults: 10,
              playlistId: PLAYLIST_ID,
              key: API_KEY,
            },
          }
        );
        const fetchedVideos = response.data.items;
        setVideosList(fetchedVideos);
        // Передаємо список відео в батьківський компонент
        setVideos(fetchedVideos);
      } catch (error) {
        console.error("Error fetching playlist:", error);
      }
    };
    fetchPlaylist();
  }, [setVideos]);

  return (
    <main className="playlist">
      <h2 className="playlist__title">{t("playlist")}</h2>
      <ul className="playlist__ul">
        {videosList.map((video, index) => {
          const isCurrent = video.snippet.resourceId.videoId === currentVideoId; // Перевіряємо, чи це поточний трек
          return (
            <li
              className={`playlist__li ${
                isCurrent ? "playlist__li--active" : ""
              }`} // Додаємо клас для активного треку
              key={video.id}
              onClick={() =>
                selectVideo(video.snippet.resourceId.videoId, index)
              }
            >
              <img
                className="playlist__img"
                src={video.snippet.thumbnails.default.url}
                alt="songImg"
              />
              <div className="playlist__col">
                <p className="playlist__author">
                  {video.snippet.videoOwnerChannelTitle.replace(" - Topic", "")}
                </p>
                <p className="playlist__song">{video.snippet.title}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </main>
  );
};
