import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import VanillaTilt from "vanilla-tilt";

import { ProjectCard } from "./card";

import foxImg from "../../../assets/img/foxminded.jpg";
import FlagLineIng from "../../../assets/img/flag-line.png";

import MokeupGif from "../../../assets/gif/mokeup.gif";
import SunsetGif from "../../../assets/gif/sunset.gif";
import CSSDocsGif from "../../../assets/gif/CSSDocs.gif";
import FlagsGif from "../../../assets/gif/Flags.gif";
import nextFoodGif from "../../../assets/gif/nextFood.gif";
import RestaurantGif from "../../../assets/gif/RestaurantMenu.gif";
import SliderPexelsGif from "../../../assets/gif/sliderPexels.gif";
import WeatherWidgetGif from "../../../assets/gif/weatherWidget.gif";
import MarvelGif from "../../../assets/gif/Marvel.gif";
import InacademyImg from "../../../assets/gif/inacademy.gif";

import "./tabsStyle.scss";

export const TabsGrid = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);

  const downloadImage = (imgSrc) => {
    const link = document.createElement("a");
    link.href = imgSrc;
    link.download = "image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <main className="tabGrid">
      <img className="tabGrid__flagLine" src={FlagLineIng} alt="FlagLine" />
      <nav className="tabGrid__nav">
        <button
          className={`tabGrid__tab ${
            activeTab === 1 ? "tabGrid__tab--active" : ""
          }`}
          onClick={() => setActiveTab(1)}
        >
          {t("student_projects")}
        </button>

        <button
          className={`tabGrid__tab ${
            activeTab === 2 ? "tabGrid__tab--active" : ""
          }`}
          onClick={() => setActiveTab(2)}
        >
          {t("business_projects")}
        </button>

        <button
          className={`tabGrid__tab ${
            activeTab === 3 ? "tabGrid__tab--active" : ""
          }`}
          onClick={() => setActiveTab(3)}
        >
          {t("my_certificates")}
        </button>
      </nav>

      <section className="tabGrid__layout">
        {activeTab === 1 && <StudentTab t={t} />}
        {activeTab === 2 && <BusinessTab t={t} />}
        {activeTab === 3 && <CerteficateTab downloadImage={downloadImage} />}
      </section>
    </main>
  );
};

export const StudentTab = ({ t }) => {
  const projects = [
    {
      title: "Sunset",
      gifSrc: SunsetGif,
      url: "https://sunset-d77d3.web.app/",
      url2: "https://github.com/akoval29/Sunset",
      tech: ["react", "redux", "javaScript", "html", "css"],
      description: t("react_redux_app"),
    },
    {
      title: "Nextlevel food",
      gifSrc: nextFoodGif,
      url: "https://food-next-js-nu.vercel.app/",
      url2: "https://github.com/akoval29/Food_NextJS",
      tech: ["next-js", "react", "javaScript", "html", "css"],
      description: t("tasty_food_recipes"),
    },
    {
      title: "Slider",
      gifSrc: SliderPexelsGif,
      url: "https://slider-f00ff.web.app/",
      url2: "https://github.com/akoval29/Slider",
      tech: ["react", "javaScript", "html", "css"],
      description: t("pexels_slider"),
    },
    {
      title: "Marvel heroes",
      gifSrc: MarvelGif,
      url: "https://marvel-3d657.web.app/",
      url2: "https://github.com/akoval29/Marvel",
      tech: ["react", "javaScript", "html", "css"],
      description: t("marvel_heroes_app"),
    },
    {
      title: "Where in the world ?",
      gifSrc: FlagsGif,
      url: "https://flags-mauve.vercel.app/",
      url2: "https://github.com/akoval29/Flags",
      tech: ["javaScript", "html", "css"],
      description: t("country_info_app"),
    },
    {
      title: "Weather widget",
      gifSrc: WeatherWidgetGif,
      url: "https://weather-widget-tau.vercel.app/",
      url2: "https://github.com/akoval29/WeatherWidget",
      tech: ["javaScript", "html", "css"],
      description: t("weather_widget"),
    },
    {
      title: "Mokeup",
      gifSrc: MokeupGif,
      url: "https://akoval29.github.io/Mockup/",
      url2: "https://github.com/akoval29/Mockup",
      tech: ["html", "css"],
      description: t("adaptive_responsive_app"),
    },
    {
      title: "Restaurant menu",
      gifSrc: RestaurantGif,
      url: "https://akoval29.github.io/RestaurantMenu/",
      url2: "https://github.com/akoval29/RestaurantMenu",
      tech: ["html", "css"],
      description: t("restaurant_menu_app"),
    },
    {
      title: "CSS Documentation",
      gifSrc: CSSDocsGif,
      url: "https://akoval29.github.io/CSS-Documentation/",
      url2: "https://github.com/akoval29/CSS-Documentation",
      tech: ["html", "css"],
      description: t("css_guide_website"),
    },
  ];

  return (
    <div className="projectGrid">
      {projects.map((project, index) => (
        <ProjectCard key={index} {...project} />
      ))}
    </div>
  );
};

export const BusinessTab = ({ t }) => {
  const projects = [
    {
      title: "inacademy MBO",
      gifSrc: InacademyImg,
      url: "https://www.inacademy.nl/",
      url2: null,
      tech: ["react", "javaScript", "html", "css"],
      description: t("dutch_language_courses"),
    },
    // Додайте більше бізнес проектів тут
  ];

  return (
    <div className="projectGrid">
      {projects.map((project, index) => (
        <ProjectCard key={index} {...project} />
      ))}
    </div>
  );
};

const CerteficateTab = ({ downloadImage }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const images = document.querySelectorAll(".tilt-img");
    VanillaTilt.init(images, {
      max: 25,
      speed: 200,
      glare: true,
      "max-glare": 0.5,
    });
  }, []);

  return (
    <>
      <img
        title={t("click_to_download")}
        className="tabGrid__img tilt-img"
        src={foxImg}
        alt="Foxminded certificate"
        onClick={() => downloadImage(foxImg)}
      />
    </>
  );
};
