import React, { useEffect, useRef } from "react";
import "./animateStyle.scss";

export const ParticleCanvas = ({ headerResize }) => {
  const canvasRef = useRef(null);
  const particles = useRef([]);
  const animationFrameRef = useRef(null);

  const properties = {
    bgColor: "rgba(0, 0, 0, 0)", // Прозорий фон
    particleColor: "rgba(255, 255, 255, 1)", // Білі крапки
    lineColor: "rgba(255, 255, 255, 1)", // Білі лінії
    particleRadius: 3,
    particleCount: 20,
    particleMaxVelocity: 0.5,
    lineLength: 150,
    particleLife: 15,
  };

  // Функція для створення нової частинки
  const createParticle = () => {
    const canvas = canvasRef.current;
    if (!canvas) return null;
    const { width, height } = canvas;
    return {
      x: Math.random() * width,
      y: Math.random() * height,
      velocityX:
        Math.random() * (properties.particleMaxVelocity * 2) -
        properties.particleMaxVelocity,
      velocityY:
        Math.random() * (properties.particleMaxVelocity * 2) -
        properties.particleMaxVelocity,
      life: Math.random() * properties.particleLife * 60,
    };
  };

  // Оновлення позиції та життєвого циклу частинки
  const updateParticle = (particle) => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const { width, height } = canvas;
    particle.life--;
    if (particle.life < 1) {
      Object.assign(particle, createParticle());
    }
    if (
      (particle.x + particle.velocityX > width && particle.velocityX > 0) ||
      (particle.x + particle.velocityX < 0 && particle.velocityX < 0)
    ) {
      particle.velocityX *= -1;
    }
    if (
      (particle.y + particle.velocityY > height && particle.velocityY > 0) ||
      (particle.y + particle.velocityY < 0 && particle.velocityY < 0)
    ) {
      particle.velocityY *= -1;
    }
    particle.x += particle.velocityX;
    particle.y += particle.velocityY;
  };

  // Малювання частинки
  const drawParticle = (ctx, particle) => {
    ctx.beginPath();
    ctx.arc(particle.x, particle.y, properties.particleRadius, 0, Math.PI * 2);
    ctx.closePath();
    ctx.fillStyle = properties.particleColor;
    ctx.fill();
  };

  // Малювання ліній між близькими частинками
  const drawLines = (ctx) => {
    for (let i = 0; i < particles.current.length; i++) {
      for (let j = i + 1; j < particles.current.length; j++) {
        const p1 = particles.current[i];
        const p2 = particles.current[j];
        const distance = Math.sqrt(
          Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2)
        );
        if (distance < properties.lineLength) {
          const opacity = 1 - distance / properties.lineLength;
          ctx.lineWidth = 0.5;
          ctx.strokeStyle = `rgba(255, 255, 255, ${opacity})`; // Білі лінії з прозорістю
          ctx.beginPath();
          ctx.moveTo(p1.x, p1.y);
          ctx.lineTo(p2.x, p2.y);
          ctx.closePath();
          ctx.stroke();
        }
      }
    }
  };

  // Основний цикл анімації
  const animate = (ctx) => {
    if (!canvasRef.current) return;

    // Очищаємо попередній вміст
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    // Малюємо кожну частинку
    particles.current.forEach((particle) => {
      updateParticle(particle);
      drawParticle(ctx, particle);
    });

    // Малюємо лінії між частинками
    drawLines(ctx);

    // Запускаємо наступний кадр
    animationFrameRef.current = requestAnimationFrame(() => animate(ctx));
  };

  // Ініціалізація та запуск анімації
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Оновлюємо розміри для точного масштабу
    canvas.width = canvas.parentElement.clientWidth;
    canvas.height = headerResize ? 55 : 80;

    // Створення початкових частинок
    for (let i = 0; i < properties.particleCount; i++) {
      const newParticle = createParticle();
      if (newParticle) particles.current.push(newParticle);
    }

    // Запуск анімації
    animate(ctx);

    // Очищення на демонтованому етапі
    return () => {
      particles.current = [];
      cancelAnimationFrame(animationFrameRef.current);
    };
  }, [headerResize]);

  const canvasHeightClass = headerResize
    ? "particles-flow--short"
    : "particles-flow--normal";

  return (
    <div className={`particles-flow ${canvasHeightClass}`}>
      <canvas className="particles-grid" ref={canvasRef} width="100%" />
    </div>
  );
};
